import { NavLink } from '@remix-run/react';
import { ReactNode } from 'react';
import classNames from 'classnames';
import IconLoading from '~/components/icons/streamline/line/IconLoading';

function LinkIcon(props: { children?: ReactNode; left?: boolean }) {
  if (!props.children) {
    return <></>;
  }

  return (
    <div
      className={classNames('size-4 inline-block', {
        'ml-1': props.left !== true,
        'mr-1': props.left === true,
      })}
    >
      {props.children}
    </div>
  );
}

export default function Link(props: {
  onClick?: () => void;
  children: ReactNode;
  to?: string;
  back?: boolean;
  icon?: ReactNode;
  left?: boolean;
}) {
  const classes =
    'text-blue-600 font-medium group-focus-visible:bg-white group-focus-visible:ring-2 group-focus-visible:ring-theme-secondary group-focus-visible:ring-offset-4 rounded inline-flex items-center hover:text-black cursor-pointer';

  if (props.to?.includes('http')) {
    return (
      <a href={props.to} className={classes} target="_blank">
        {props.left === true && <LinkIcon left>{props.icon}</LinkIcon>}
        {props.children}
        {props.left !== true && <LinkIcon>{props.icon}</LinkIcon>}
      </a>
    );
  }

  if (props.to) {
    return (
      <NavLink to={props.to} className={classes} viewTransition>
        {({ isPending }) => (
          <>
            {(props.left === true || !props.icon) && (
              <LinkIcon left>{isPending ? <IconLoading /> : props.icon}</LinkIcon>
            )}
            {props.children}
            {props.left !== true && props.icon && <LinkIcon>{isPending ? <IconLoading /> : props.icon}</LinkIcon>}
          </>
        )}
      </NavLink>
    );
  }

  return (
    <span
      onClick={(e) => {
        e.preventDefault();

        if (props.onClick) {
          props.onClick();
        } else if (props.back) {
          history.back();
        }
      }}
      className={classes}
    >
      {props.left === true && <LinkIcon left>{props.icon}</LinkIcon>}
      {props.children}
      {props.left !== true && <LinkIcon>{props.icon}</LinkIcon>}
    </span>
  );
}
